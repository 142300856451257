import React from 'react'
import CarbonRemoval from './CarbonRemoval';
import Hero from './Hero'
import Projects from './Projects'
import './projects.css'

function ProjectsLayout() {

     React.useEffect(() => {
  
      window.scrollTo(0, 0);


  }, []);

    return (
        <div className="projects-container">
            <Hero />
            <CarbonRemoval />
            <Projects />
            <div className="projects-footer">
                <span>Are you a project/supplier that wants to partner with us? Email us: info@vizopay.com</span>
            </div>
        </div>
    )
}

export default ProjectsLayout
