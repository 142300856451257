import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import flowDiagram from '../../assets/flow-chart-web.png'
import coffeeImage from '../../assets/pay-contactless.jpeg';
import cardLink from '../../assets/Images/card-link.jpeg';
import ipadScreen from '../../assets/Images/ipad-screen.png';
import {motion } from 'framer-motion';

function HowItWorks() {

    
    return (
        <Container className="hiw-container">
            <Row >
                <h1 className="impact-title"><motion.span 
                    initial={{fontSize: "0.5rem", opacity: 0}}
                      whileInView={{ fontSize: "6rem", opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{
    duration: 0.25,
    delay: 0.1
  }}
                style={{color: '#65ccb8'}}>Growth</motion.span> starts here.</h1>
                <span className="sub-heading">Being eco-friendly is expensive, confusing and time consuming. Our solution makes being sustainable easy and provides your business and customers with direct value.</span>
            </Row>
            <Row>
                <Col md={6} style={{padding: "2% 2% 0% 2%"}}>

                <motion.div 
                    initial={{scale: 0.3, opacity: 0}}
                    whileInView={{scale:1, opacity: 1}}
                    viewport={{once: true}}
                    transition={{
                    duration: 0.7,
                    type: "spring",
                    stiffness: 60,
                    delay: 0 
                }}
                    
                className="growth-containers"> 
                
                        <h1>Reach</h1>
                        <h5>a growing network of new customers.</h5>
                    
                </motion.div>

                </Col>
                <Col md={6} style={{padding: "2% 2% 0px 2%"}}>

                <motion.div 
                     initial={{scale: 0.3, opacity: 0}}
                    whileInView={{scale:1, opacity: 1}}
                viewport={{once: true}}
                transition={{
                    duration: 0.7,
                    type: "spring",
                    stiffness: 60,
                    delay: 0.2 
                }}

                className="growth-containers" >

                        <h1>Retain</h1>
                        <h5>spenders through our AI-driven loyalty network.</h5>
           
                </motion.div>

                </Col>
            </Row>
            <Row>
                <Col md={6} style={{padding: "2% 2% 0% 2%"}}>

                <motion.div 
                   initial={{scale: 0.3, opacity: 0}}
                    whileInView={{scale:1, opacity: 1}}
                viewport={{once: true}}
                transition={{
                    duration: 0.7,
                    type: "spring",
                    stiffness: 60,
                    delay: 0
                }}
                className="growth-containers">
                 
                        <h1>Increase</h1>
                        <h5>average transactions and spending frequency.</h5>
                 
                </motion.div>

                </Col>
                <Col md={6} style={{padding: "2% 2% 0% 2%"}}>

                <motion.div 
                      initial={{scale: 0.3, opacity: 0}}
                    whileInView={{scale:1, opacity: 1}}
                viewport={{once: true}}
                transition={{
                    duration: 0.7,
                    type: "spring",
                    stiffness: 60,
                    delay: 0.2 
                }}

                className="growth-containers">
                 
             
                            <h1>Showcase</h1>                 
                        <h5>your sustainability efforts in a concise way.</h5>
                
               
                </motion.div>

                </Col>
            </Row>

            {/* FLOW FUNDS */}
            <Row style={{margin: "40px auto"}}>
                <Col lg={6} className="flex-top-center">
                    <div>
                        <h2 style={{fontWeight: '700', margin: '20px 0', fontSize: '3rem'}}><motion.span
                        initial={{color: "#222831"}}
                        whileInView={{color: "#65ccb8"}}
                        viewport={{once: true}}
                        transition={{
                            duration: 0.5,
                            delay: 0.2
                        }}
                        >Net zero</motion.span>, made simple</h2>
                    <span>Vizopay makes every purchase carbon neutral so you can spend more time focusing on your business. With Vizopay your brand will become more verifiably sustainable by funding carefully selected carbon removal projects.</span>

                    </div>
                </Col>
                <Col lg={6} className="center-flex">
                    <div className="flow-diagram-container qr-div">
                        <img src={flowDiagram} width="100%"/>
                        <div className="flow-fund-circle-1"></div>
                        <div className="flow-fund-circle-2"></div>
                        <div className="flow-fund-circle-3"></div>


                    </div>
                </Col>
            </Row>
            {/*END FLOW FUNDS */}

            {/* FLOW FUNDS */}

            {/* <Row >
                <div>
                        <h2 style={{fontWeight: '700', margin: '20px 0', fontSize: '3rem'}}>Accept fast, secure and sustainable payments in less than 10 mins</h2>
                    <span>Get started in lightning speed with no hardware. We don't replace your current payment system, we work alongside it.</span>

                    </div>
            </Row>

             <Row className="full-page-section">
                <Col lg={6} className="center-flex">
                    <img src={cardLink} width="320px"/>
                </Col>
                <Col lg={6} className="center-flex">
                     <div>
                        <h2 style={{fontWeight: '700', margin: '20px 0'}}>Card-Linked Loyalty</h2>
                    <span style={{margin: "10px auto"}}>Customers link their bank card and automatically collect stamps, points and custom rewards with your business.</span>
                    <br />
                    <span style={{margin: "10px auto"}}>All rewards are unique to your business, they are non-transferable.</span>


                    </div>
                </Col>
            </Row>

                 <Row className="full-page-section">
        
                <Col lg={6} className="center-flex">
                     <div>
                        <h2 style={{fontWeight: '700', margin: '20px 0'}}>Connect with your customers</h2>
                    <span>We give you the tools to connect with your customers and access a network of growing users.</span>

                    </div>
                </Col>

                 <Col lg={6} className="center-flex">
                    <img src={ipadScreen} style={{width: '100%', maxWidth: '700px'}}/>
                </Col>
            </Row>

                <Row className="full-page-section">
                <Col lg={6} className="center-flex">
                    <img src={ipadScreen} style={{width: '100%', maxWidth: '700px'}}/>
                </Col>
        
                <Col lg={6} className="center-flex">
                     <div>
                        <h2 style={{fontWeight: '700', margin: '20px 0'}}>Reach a growing user base</h2>
                    <span>We work with communities. We are actively partnering with Councils, Universities and Businesses.</span>

                    </div>
                </Col>

                 
            </Row> */}


        </Container>
    )
}

export default HowItWorks
