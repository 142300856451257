import React from 'react'

function OurMissionHeroTop () {

    return(
        <>
        <div className='OurMissionBlog-hero-top-container'></div>
        </>
    );
}

export default OurMissionHeroTop