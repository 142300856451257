import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'

function Hero() {

    
    return (
        
        
      <div className="js-swiper-blog-metro-hero swiper">
       
        <div className="swiper-wrapper">
          <div className="drone-img swiper-slide d-lg-flex min-vh-lg-100 gradient-x-overlay-lg-dark bg-img-start content-space-5" >
            {/* Container */}
               {/* <div className="floating-button">
                <Link to="/">
                    <button type="button" class="btn btn-light">Take me Home</button>
                </Link>
            </div> */}
            <div className="container d-lg-flex align-items-lg-center">
              <div className="w-lg-65 text-center mx-lg-auto">
                <a className="text-cap text-white-70" href="#">projects 2022</a>
                <div className="4 mb-md-7">
                  <h1 className="text-white">We fund meaningful projects that focus on carbon removal</h1>
                </div>
              </div>
            </div>
            {/* End Container */}
          </div>
        </div>
        {/* Swiper Pagination */}
      </div>
    
    )
}

export default Hero
