import React from 'react'
import {Container, Row, Col, Card, CardTitle, CardImg, CardBody, CardText, CardSubtitle} from 'reactstrap';

import NoChange from '../../assets/no-change.svg';
import NoCompromise from '../../assets/no-compromise.svg';
import NoCosts from '../../assets/no-costs.svg';

import {motion} from 'framer-motion'


function WhyVizopay() {

    let array = [
        {
            title: "No fees",
            subtitle: "Shopping sustainably is free with Vizopay!",
            image: NoCosts,
            paragraph: "Sustainable shopping shouldn't have to be a luxury. Vizopay makes sustainability accessible for everyone.",
        },
         {
            title: "No lifestyle changes",
            subtitle: "Same bank. Same stores. Same products.",
            image: NoChange,
            paragraph: "Simply use the Vizopay app at checkout to make your purcase carbon neutral and earn rewards at the same time.",
        },
         {
            title: "No stress",
            subtitle: "Vizopay takes the headache out of climate action",
            image: NoCompromise,
            paragraph: "A percentage of every transaction goes straight towards funding fully accredited carbon removal projects.",
        },
    ]

    return (
        <Container className="hook-section">
           <div className="hook-container">
                <Row style={{marginRight: "0px"}}>
                <div className="py-5">
                    <h1>Vizopay makes sustainability simple</h1>
                </div>
            </Row>
            <Row style={{marginRight: "0px"}}>
                {
                    array.map((item, i) => {
                        return(
                            <Col lg={4} style={{paddingLeft: "24px"}}>
                                <motion.div
                                viewport={{once: true}}
                                    key={i}
                                    initial={{opacity: 0, y: -100}}
                                    whileInView={{opacity: 1, y: 0}}
                                    transition={{
                                        type: "spring",
                                        stiffness: 200,
                                        duration: 3,
                                        delay: `0.${i+3}`
                                    }}
                                    

                                 className="why-card">
                                    <div className="top-row">
                                      <div className="image-container">
                                            <img src={item.image}/>
                                      </div>
                                    </div>
                                    <div  className="bottom-row">
                                        <h3>{item.title}</h3>
                                        <h6>{item.subtitle}</h6>
                                        <p>{item.paragraph}</p>
                                    </div>
                                </motion.div>
                            </Col>
                        )
                    })
                }
            </Row>
   
           </div>
        </Container>
    )
}

export default WhyVizopay
