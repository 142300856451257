import React, {useState, useEffect} from 'react'
import SignupForm from './SignupForm'

import './community.css'
import Introduction from './Introduction';
import Success from './Success';

function SignupCommunityLayout({match}) {

    const [step, setStep] = useState(0);

    var url_string = window.location.href
    var url = new URL(url_string);
var c = url.searchParams.get("referral");

    useEffect(() => {
        console.log("REFERRAL ID:", c)
      if (c === "SG-YYSBr") {
          setStep(1)
      }
    }, []) 

    return (
        <div className="community-container">
            {
                step === 0 ?

                <SignupForm match={match} setStep={setStep}/>

                :

                step === 1 ?

                <Introduction step={step} setStep={setStep} match={match}/>

                :

                <Success match={match} setStep={setStep}/>


            }
        </div>
    )
}

export default SignupCommunityLayout
