  
export const Data = [
  {
    question: 'How is Vizopay Sustainable? 🌲',
    answer: 'Every time a payment is made through our platform we calculate the scope 3 carbon emissions on a product-specific level using our carbon algorithm. We have partnered with accredited carbon projects globally to then offset the emissions of this purchase. We never charge you extra to be sustainable, it’s free and you get rewarded!'
  },
  {
    question: 'What’s the catch? 🎣',
    answer: 'There is no catch! To fight climate change we need a systemic solution, one where there is a balance. We believe that through carbon removals we can create a balance between consumption and climate action!'
  },
  {
    question: 'What sustainable projects do you fund? 🌱',
    answer: `We only fund carbon removal projects of the highest standard. We work with consultants and industry experts that meet a spacific critria that have long lasting effects on the planet. Please see the projects we fund in our Projects section!`
  },
  {
    question: 'How can I see my positive impact? ✅ ',
    answer: 'Sustainability can be hard to understand, thats why we simplify it in our app! We link all the carbon projects we fund to be equivelant to one e-tree (12.3 kg CO2). Your digital forest allows you to visually see the impact you are having when using Vizopay. We also let you see your offset equivelant to that of riding a train, driving a car and flying on a plane so you don’t need to feel so you can better understand emissions impact!'
  },
  {
    question: 'Does this mean I can buy single use items and still help the planet? 🗑️',
    answer: 'Well, not exactly. We promote decarbonising and sustainable practices as the first port of call when it comes to making your everyday life more environmentally friendly. Avoiding single use plastic should still be strived for! But, if buying single use plastic is unavoidable, then Vizopay will ensure that item is made net zero.'
  },
  {
    question: 'Are carbon offsets the best way to slow climate change?',
    answer: 'The best way to slow climate change is to not release carbon emissions in the first place. There are many ways to reduce your carbon footprint, but in today’s world, it’s impossible to eliminate your carbon footprint completely. There is also a lot of already exisiting carbon in the atmosphere that makes the process slower. Therefore, we focus on accreditied projects that remove carbon directly from the atmosphere and have high permanence (long-lived storage). This ensures that we can not only reach net-zero faster, but the effects remain for longer!'
  },
];