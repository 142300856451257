import React from 'react'
import Ambassadortext from './Ambassador-text';
import Hero from './Hero-Amb'
import AmbassadorPerks from './AmbassadorPerks'
import AmbassadorRes from './AmbassadorRes'
import AmbassadorForm from './AmbassadorForm'
import './Ambassador.css'
import AmbassadorWhy from './AmbassadorWhy';
import {Container, Row, Col, Button, Alert, Badge} from 'reactstrap'

function ProjectsLayout() {

     React.useEffect(() => {
  
      window.scrollTo(0, 0);


  }, []);

//ambassador-container-1 

    return (
        <div className="customer-section" fluid>
            <Hero />
            <AmbassadorWhy />
            <AmbassadorForm />
            <div className="ambassador-container-footer">
                <span>Want to learn more about being an Ambassador? Email us: info@vizopay.com</span>
            </div>
        </div>
    )
}

export default ProjectsLayout
