import React from 'react'
import { Row, Container, Col} from 'reactstrap';


function BlogLayout () {
    return (
        <>

    <div className='blog-layout-full-container blog-background'>
    <div className='blog-layout-header blog-background blog-layout-big'>
    <a href='our-mission' style={{textDecoration: "none"}}>
     <Container fluid className='blog-layout-container'>
        <Row>
         <Col className='blog-layout-large-tile-img'> </Col>
         <Col className='blog-layout-large-tile-text blog-title-container'>
             <Row>
                 <h1>Our Mission</h1>
                 <p>We aim to make sustainability easy for everyone! A win-win solution that allows consumers, businesses and the planet to benefit with every purchase.</p>
             </Row>
             <Row>
                 <a><button className='blog-footer-button' style={{marginRight: '10px'}}>Sustainability</button> May 20, 2022</a>
             </Row>
         </Col>
        </Row>
     </Container>
     </a>
     </div>

     <div className='blog-layout-header blog-background blog-layout-small'>
     <a href='our-mission' style={{textDecoration: "none"}}></a>
     <Container fluid className='blog-layout-container'>
        <Col>
         <Row className='blog-layout-large-tile-img-small'> </Row>
         <Row className='blog-layout-large-tile-text blog-title-container'>
             <Col>
                 <h1>Our Mision</h1>
                 <p>We aim to make sustainability easy for everyone! A win-win solution that allows consumers, businesses and the planet to benefit with every purchase.</p>
             </Col>
             <Col>
                 <a><button className='blog-footer-button' style={{marginRight: '10px'}}>Sustainability</button> May 20, 2022</a>
             </Col>
         </Row>
        </Col>
     </Container>
     <a/>
     </div>

     <div className='blog-layout-tags-container '>
     <Col>
        <h5>
        Tags:
        <button className='blog-footer-button'>Sustainability</button> 
         <button className='blog-footer-button' style={{backgroundColor: '#135361'}}>Vision</button>
         </h5>
    </Col>
     </div>

     <div className= 'blog-background blog-layout-grid-container blog-layout-big'>
     <Container fluid >
        <Row md={4}>
             <Col className='blog-layout-small-tile-container blog-layout-container ' md={{ span: 3, offset: 1}}>
                <Col className='blog-layout-small-tile-img'>  
                </Col>
                <Col className='blog-layout-large-grid-text'>
                 <h2>1st Title</h2>
                 <a><button className='blog-grid-button' style={{marginRight: '10px'}}>Sustainability</button> May 20, 2022</a>
                 </Col>
             </Col>
             <Col className='blog-layout-small-tile-container blog-layout-container' md={{ span: 3, offset: 1}}>
                <Col className='blog-layout-small-tile-img'>  
                </Col>
                <Col className='blog-layout-large-grid-text'>
                 <h2>2nd Title</h2>
                 <a><button className='blog-grid-button' style={{marginRight: '10px'}}>Sustainability</button> May 20, 2022</a>
                 </Col>
             </Col>
             <Col className='blog-layout-small-tile-container blog-layout-container' md={{ span: 3, offset: 1}}>
                <Col className='blog-layout-small-tile-img'>  
                </Col>
                <Col className='blog-layout-large-grid-text'>
                 <h2>3rd Title</h2>
                 <a><button className='blog-grid-button' style={{marginRight: '10px'}}>Sustainability</button> May 20, 2022</a>
                 </Col>
             </Col>
        </Row>
     </Container>
     </div>


     <div className= 'blog-background blog-layout-grid-container blog-layout-small'>
     <Container fluid >
        <Col>
             <Col className='blog-layout-small-tile-container-small blog-layout-container ' md={{ span: 3, offset: 1}}>
                <Col className='blog-layout-small-tile-img'>  
                </Col>
                <Col className='blog-layout-large-grid-text'>
                 <h2>1st Title</h2>
                 <a><button className='blog-grid-button' style={{marginRight: '10px'}}>Sustainability</button> May 20, 2022</a>
                 </Col>
             </Col>
             <Col className='blog-layout-small-tile-container-small blog-layout-container' md={{ span: 3, offset: 1}}>
                <Col className='blog-layout-small-tile-img'>  
                </Col>
                <Col className='blog-layout-large-grid-text'>
                 <h2>2nd Title</h2>
                 <a><button className='blog-grid-button' style={{marginRight: '10px'}}>Sustainability</button> May 20, 2022</a>
                 </Col>
             </Col>
             <Col className='blog-layout-small-tile-container-small blog-layout-container' md={{ span: 3, offset: 1}}>
                <Col className='blog-layout-small-tile-img'>  
                </Col>
                <Col className='blog-layout-large-grid-text'>
                 <h2>3rd Title</h2>
                 <a><button className='blog-grid-button' style={{marginRight: '10px'}}>Sustainability</button> May 20, 2022</a>
                 </Col>
             </Col>
        </Col>
     </Container>
     </div>

     </div>


     </>
    );
  }

export default BlogLayout