import React from 'react'
import { Link, Redirect } from 'react-router-dom'

function AppSupport() {
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#65ccb8', flexDirection: 'column', padding: '7% 5% 0% 5%'}}>
            <div style={{display: 'flex', maxWidth: '500px', flexDirection: 'column', margin: '20px auto', backgroundColor: '#fff', padding: 20, borderRadius: 20}}>
                <h3>Get in touch</h3>
                <p>For questions on Vizopay, please get in touch: <strong>info@vizopay.com</strong></p>
                <p>For further queries or feedback, please email us here: <strong>feedback@vizopay.com</strong></p>
                <p>We aim to respond within 1 working day.</p>
            </div>
            <div style={{display: 'flex', maxWidth: '500px', flexDirection: 'column', backgroundColor: '#fff', padding: 20, borderRadius: 20}}>
                <h3>App Support Contact Information:</h3>
                <h5>support@vizopay.com</h5>
                <p>Please attach screenshots and phone number so we can look into the issue for you.</p>
            </div>

            <div style={{padding: "3%", zIndex: "22"}}>
            <Link to="/">
            <button type="submit" className="btn btn-success transition-3d-hover" style={{color: '#fff', fontWeight: '800'}}>Return home</button>

            </Link>
            </div>

        </div>
    )
}

export default AppSupport