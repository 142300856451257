import React, {useState} from 'react'
import {Link} from 'react-router-dom';
import {Container, Row, Col} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

function Footer() {

    const [instagramHover, setInstagramHover] = useState(false)
    const [linkedInHover, setLinkedInHover] = useState(false)
    const [twitterHover, setTwitterHover] = useState(false)



    return (
         <Container fluid className="vp-footer-container">

         <Row>
             <Col style={{margin: "40px 0"}} md={7}>
                <h1>Don't be shy, say Hi 👋</h1>
                <br />
                <h3 style={{color: '#65ccb8', fontWeight: '700'}}>info@vizopay.com</h3>
                <br />
                
                <Row>
                    <div className="social-container">
                        <a onMouseOver={() => {setInstagramHover(true)}} onMouseLeave={() => setInstagramHover(false)} target="__blank" href="https://instagram.com/vizopay">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510.91 511"><defs><style dangerouslySetInnerHTML={{__html: `.ig-1{fill:${instagramHover ? "#fe2b63" : "#fff"};}` }} /></defs><path className="ig-1" d="M510.5,150.24c-1.2-27.16-5.59-45.82-11.88-62a130.85,130.85,0,0,0-74.77-74.76C407.58,7.19,389,2.8,361.86,1.6,334.51.3,325.83,0,256.45,0S178.39.3,151.13,1.5s-45.81,5.59-62,11.87A124.87,124.87,0,0,0,43.82,42.92,125.87,125.87,0,0,0,14.37,88.15C8.08,104.42,3.69,123,2.5,150.13,1.2,177.49.9,186.17.9,255.55s.3,78.06,1.49,105.31,5.6,45.82,11.89,62a125.88,125.88,0,0,0,29.54,45.32A125.79,125.79,0,0,0,89,497.62c16.28,6.29,34.84,10.68,62,11.88s36,1.5,105.35,1.5,78.06-.3,105.31-1.5,45.82-5.59,62-11.88a130.68,130.68,0,0,0,74.77-74.76c6.29-16.28,10.68-34.84,11.88-62s1.5-35.93,1.5-105.31S511.7,177.49,510.5,150.24Zm-46,208.63c-1.1,24.95-5.29,38.43-8.78,47.41A84.75,84.75,0,0,1,407.2,454.8c-9,3.49-22.56,7.69-47.41,8.78-27,1.2-35,1.5-103.22,1.5s-76.37-.3-103.22-1.5c-25-1.09-38.43-5.29-47.42-8.78A84.42,84.42,0,0,1,57.5,406.39c-3.5-9-7.69-22.57-8.78-47.42-1.21-27-1.5-35-1.5-103.22s.29-76.37,1.5-103.22c1.09-25,5.28-38.43,8.78-47.41A77.45,77.45,0,0,1,76.67,75.76,79.31,79.31,0,0,1,106,56.7c9-3.49,22.57-7.68,47.42-8.78,27-1.2,35-1.5,103.22-1.5s76.36.3,103.22,1.5c24.95,1.1,38.43,5.29,47.41,8.78a78.65,78.65,0,0,1,29.35,19.06,79.45,79.45,0,0,1,19.07,29.36c3.49,9,7.68,22.55,8.78,47.41,1.2,26.95,1.5,35,1.5,103.22s-.29,76.17-1.49,103.12Z" transform="translate(-0.9)" /><path className="ig-1" d="M256.45,124.28A131.27,131.27,0,1,0,387.72,255.55h0A131.31,131.31,0,0,0,256.45,124.28Zm0,216.42a85.15,85.15,0,1,1,85.15-85.15h0A85.17,85.17,0,0,1,256.45,340.7Z" transform="translate(-0.9)" /><path className="ig-1" d="M423.56,119.09A30.65,30.65,0,1,1,392.9,88.45h0A30.66,30.66,0,0,1,423.56,119.09Z" transform="translate(-0.9)" /></svg>
                        </a>                    
                    </div>
                    <div className="social-container">
                        <a onMouseOver={() => {setLinkedInHover(true)}} onMouseLeave={() => setLinkedInHover(false)} target="__blank" href="https://linkedin.com/company/vizopay">
                             <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><defs><style dangerouslySetInnerHTML={{__html: `.li-1{fill:${linkedInHover ? "#0077b5" : "#222831"};}.li-2{fill:#fff;}` }} /></defs><g id="Layer_2" data-name="Layer 2"><g id="linkedin"><rect id="background" className="li-1" width={512} height={512} rx="69.82" /><g id="icon"><path className="li-2" d="M184.44,139.64A43.64,43.64,0,1,1,140.8,96,43.63,43.63,0,0,1,184.44,139.64Z" /><path className="li-2" d="M174.55,212.36V405.15A10.8,10.8,0,0,1,163.81,416h-46a10.78,10.78,0,0,1-10.82-10.76V212.36a10.82,10.82,0,0,1,10.82-10.82h46a10.83,10.83,0,0,1,10.8,10.82Z" /><path className="li-2" d="M415,312.73v93.32a9.92,9.92,0,0,1-9.89,9.95H355.55a9.92,9.92,0,0,1-9.95-9.89v-90.5c0-13.5,4-59.12-35.29-59.12-30.4,0-36.59,31.22-37.82,45.24V406.05a9.94,9.94,0,0,1-9.8,9.95H214.92a9.92,9.92,0,0,1-9.92-9.92V211.52a9.93,9.93,0,0,1,9.89-10h47.8a10,10,0,0,1,9.95,10v16.81c11.29-16.93,28-30,63.71-30,79.07,0,78.6,73.83,78.6,114.39Z" /></g></g></g></svg>                            
                        </a>                    
                    </div>

                    <div className="social-container">
                        <a onMouseOver={() => {setTwitterHover(true)}} onMouseLeave={() => setTwitterHover(false)} target="__blank" href="https://twitter.com/vizopayofficial">
                     <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 416"><defs><style dangerouslySetInnerHTML={{__html: `.cls-1{fill:${twitterHover ? "#03a9f4" : "#fff"};}` }} /></defs><path className="cls-1" d="M512,97.25a218.64,218.64,0,0,1-60.48,16.57,104.36,104.36,0,0,0,46.18-58,210,210,0,0,1-66.56,25.41A105,105,0,0,0,249.57,153,108,108,0,0,0,252,176.93C164.74,172.67,87.52,130.85,35.65,67.14A105,105,0,0,0,67.9,207.42,103.69,103.69,0,0,1,20.48,194.5v1.15a105.43,105.43,0,0,0,84.1,103.13,104.65,104.65,0,0,1-27.52,3.46,92.77,92.77,0,0,1-19.88-1.79c13.6,41.57,52.2,72.13,98.08,73.12A210.93,210.93,0,0,1,25.12,418.34,197.72,197.72,0,0,1,0,416.9,295.54,295.54,0,0,0,161,464c193.16,0,298.76-160,298.76-298.69,0-4.64-.16-9.12-.39-13.57A209.29,209.29,0,0,0,512,97.25Z" transform="translate(0 -48)" /></svg>
                             
                        </a>                    
                    </div>

                    
                    

                </Row>
             </Col>


             <Col style={{margin: "40px 0"}} md={5}>
              
                <Row>
                <Col>
                <div>
                    <h3>Vizopay</h3>
                </div>
                <div className="footer-links">
                    <a target="__blank" href="https://intercom.help/vizopay/en/">FAQs</a>
                    <a target="__blank" href="https://vizopay.com/terms/">Terms</a>
                    <a target="__blank" href="https://vizopay.com/privacy-policy/">Privacy</a>

                </div>
                </Col>
              
                <Col>
                <div>
                    <h3>Info</h3>
                </div>
                 <div className="footer-links">
                     <a target="__blank" href="https://intercom.help/vizopay/en/">Business Help</a>
                    <Link to="/">Press</Link>
                    <Link to="/">Investors</Link>

                </div>
                </Col>
                </Row>
                
             </Col>
         </Row>
         <Row className="address-container">
            <Col style={{marginTop: 40, marginBottom: 40}}>
            <div className="footer-address">
            <h4 style={{fontWeight: '600'}}>Exeter (HQ)</h4>
                <small style={{fontWeight: '500'}}>Vizopay Ltd</small>
            <small style={{fontWeight: '500'}}>Queensgate House</small>
            <small style={{fontWeight: '500'}}>48 Queen Street</small>
            <small style={{fontWeight: '500'}}>Exeter</small>
            <small style={{fontWeight: '500'}}>Devon</small>
            <small style={{fontWeight: '500'}}>EX4 3SR</small>
            </div>
            </Col>
            <Col style={{marginTop: 40, marginBottom: 40}}>
            <div className="footer-address">
            <h4 style={{fontWeight: '600'}}>London</h4>
                <small style={{fontWeight: '500'}}>Vizopay Ltd</small>
            <small style={{fontWeight: '500'}}>71-75 Shelton Street</small>
            <small style={{fontWeight: '500'}}>Covent Garden</small>
            <small style={{fontWeight: '500'}}>London</small>
            <small style={{fontWeight: '500'}}>WC2H 9JQ</small>
            </div>
            </Col>
         </Row>

  </Container>
    )
}

export default Footer
