import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import QRCode from '../../assets/qr-code.svg'
import HeroImg from './placeholder-hero.png'

import {motion} from 'framer-motion'

import { Typewriter, useTypewriter, Cursor } from 'react-simple-typewriter'


function HeroTopAlt() {

    const {text} = useTypewriter({
        words: ['purchase', 'coffee', 'smoothie', 'frappé', 'latte', 'milkshake', 'nibble', 'lunch', 'pizza'],
        loop: [0], // Infinit
      })
    
    return (
        <Container className="hero-alt" fluid>
            <Row >
                <Col lg={2}></Col>
                <Col className="vh-100"  lg={8}>
                    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <div style={{minWidth: '100%'}} className="middle-col-top web-header">
                        <h1 style={{maxWidth: '800px'}}>Make every 
                         <span style={{color: '#65ccb8'}}> #{text}</span> </h1> 
                        
                        <h1>sustainable<span style={{color: '#65ccb8'}}>.</span> </h1>
                        <small style={{fontWeight: '500', fontSize: '1.2rem'}}>It doesn't matter what you buy. Vizopay rewards you and the planet for shopping sustainably, for free.</small>
                    </div>

                    <div style={{minWidth: '100%', padding: '100px 0% 0% 0%'}} className="middle-col-top mobile-header">
                        <h1 style={{maxWidth: '800px'}}>Make every </h1>
                       
                         <h1> <span style={{color: '#65ccb8'}}> #{text}</span> </h1> 
                        
                        <h1>sustainable<span style={{color: '#65ccb8'}}>.</span> </h1>
                        <small style={{fontWeight: '500', fontSize: '1.2rem'}}>It doesn't matter what you buy. Vizopay rewards you and the planet for shopping sustainably, for free.</small>
                    </div>

                    {/* <div className="middle-col-top">
                        <h1>Make evey <span style={{color: '#65ccb8'}}>#nibble</span> <span style={{color: '#8D8DAA'}}>loyalty</span> app </h1>
                        <small>It doesn't matter what you buy. Vizopay rewards you and the planet for shopping sustainably, for free</small>
                    </div> */}


                    <div className='middle-col-bottom mobile-button' style={{paddingTop: '35%'}}>
                        <a target="_blank" href="https://vizopay.app.link" className="a-button btn btn-primary">Download App</a>
                    </div> 
                    


                    <div className="middle-col-bottom qr-div">
                    <div
                     style={{marginBottom: 20}}>
                        <button style={{minWidth: 200, height: 50}} className="btn btn-primary">

                            <small style={{fontSize: '1rem', color: '#ffffff', fontWeight:600}}>Scan to download the app</small>
                        </button>
                        </div>
                        <div 
                       
                        className="qr-container">
                        <motion.div
                          viewport={{once: true}}
                             initial={{scale: 0, opacity: 0}}
                    whileInView={{scale: 1, opacity: 1}}
                    transition={{
                        type: 'spring',
                        stiffness: 200,
                        duation: 2
                    }}
                         className="cross-hair-1"></motion.div>
                        <motion.div
                          viewport={{once: true}}
                             initial={{scale: 0, opacity: 0}}
                    whileInView={{scale: 1, opacity: 1}}
                    transition={{
                        type: 'spring',
                        stiffness: 200,
                        duation: 2
                    }}
                         className="cross-hair-2"></motion.div>
                        <motion.div
                          viewport={{once: true}}
                             initial={{scale: 0, opacity: 0}}
                    whileInView={{scale: 1, opacity: 1}}
                    transition={{
                        type: 'spring',
                        stiffness: 200,
                        duation: 2
                    }}
                         className="cross-hair-3"></motion.div>
                        <motion.div
                          viewport={{once: true}}
                             initial={{scale: 0, opacity: 0}}
                    whileInView={{scale: 1, opacity: 1}}
                    transition={{
                        type: 'spring',
                        stiffness: 200,
                        duation: 2
                    }}
                         className="cross-hair-4"></motion.div>

                            <img src={QRCode} width={'100%'}/>
                        </div>
                    </div>
                    </div>
                   
                  
                </Col>
                <Col lg={2}>
                    {/* <div className="right-col">
                        <img src={HeroImg} width="100%"/>

                    </div> */}
                </Col>

            </Row>
        </Container>
    )
}

export default HeroTopAlt
