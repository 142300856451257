import React, {useState, useEffect} from 'react'
import {Container, Row, Col, Form, FormGroup, Input, Label, Button} from 'reactstrap';

import FormImage from '../../assets/AmbassadorForm.jpg';

import axios from 'axios';
import url from '../../api/url';

function AmbassadorForm() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [instagramHandle, setinstagramHandle] = useState("");
    const [whyAmbassador, setwhyAmbassador] = useState("")

    const [emailValid, setEmailValid] = useState(false);
    const [nameValid, setNameValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [instagramHandleValid, setinstagramHandleValid] = useState(false);
    const [whyAmbassadorValid, setwhyAmbassadorValid] = useState(false);

    const [formPressed, setFormPressed] = useState(false)

    const [successMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState(false)

    // VALIDATION

    const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

    useEffect(() => {

        if(!email) {
            setEmailValid(false)

            return;
             
        } else {
            let emailIsValid = validateEmail(email)

            setEmailValid(emailIsValid)
        }    

    }, [email])

    useEffect(() => {

        if(!email) {
            setPhoneValid(false)

            return;
             
        } else {
            let testPhone = /^(?:\W*\d){11}\W*$/.test(phone)


            setPhoneValid(testPhone)
        }    

    }, [phone])

       useEffect(() => {

        if(!name) {
            setNameValid(false)

            return;
             
        } else {

            setNameValid(true)
        }    

    }, [name])


       useEffect(() => {

        if(!instagramHandle) {
            setinstagramHandleValid(false)

            return;
             
        } else {

            setinstagramHandleValid(true)
        }    

    }, [instagramHandle])


       useEffect(() => {


        if(!whyAmbassador) {
            setwhyAmbassadorValid(false)

            return;
             
        } else {

            setwhyAmbassadorValid(true)
        }    

    }, [whyAmbassador])

    const handleSubmit = async(e) => {

        setFormPressed(true)

        e.preventDefault()

        if(!emailValid || !nameValid || !phoneValid || !instagramHandleValid || !whyAmbassadorValid) {
            return;
        }

          let body = {
            name: name,
            email: email,
            phone: phone,
            ig_handle: instagramHandle,
            description: whyAmbassador,
      }

         await axios.post(`${url}/ambassador-contact`, body)
          .then(res => {
              setErrorMessage(false)
              setSuccessMessage(true)
          }
            )
          .catch(err => {
            console.log(err.response)
            setErrorMessage(true)
          })

        setSuccessMessage(!successMessage)

    }


    return (
        <Container style={{zIndex: 2, backgroundColor: '#fff'}} className="business-form-container">
            <Row>
                <Col xl={6}>

                    {/* header */}

                    <Row>
                    <h1 style={{fontWeight: '700'}}>Ready to join the team?</h1>
                    </Row>
                      <Row>
                                         <small style={{fontWeight: '500'}}>See yourself being an Ambassador?</small>
                    <small style={{fontWeight: '500'}}>Fill in your details below and a member of our team will be in touch!</small>
                        </Row>
 
                    <Row>
                    <Form className="business-form">
                          <FormGroup className="form-group">

                            <Label className="floating-label" style={name ? {transform: "translate(0, 6px) scale(0.75)"} : null} for="name">
      Name
    </Label>
   
    <Input
      id="name"
      name="name"
      type="text"
           valid={nameValid ? true : null}
           invalid={!nameValid && name ? true : formPressed && !nameValid ? true : null}

          onChange={(e) => setName(e.target.value)}
          
    />
   
  </FormGroup>
                  <FormGroup className="form-group">
    <Label className="floating-label" style={email ? {transform: "translate(0, 6px) scale(0.75)"} : null} for="email">
      Email Address
    </Label>
    <Input
      id="email"
      name="email"
           valid={emailValid ? true : null}
           invalid={!emailValid && email ? true : formPressed && !emailValid ? true : null}
    onChange={(e) => setEmail(e.target.value)}
      type="email"
    />
  </FormGroup>
    <FormGroup className="form-group">
    <Label className="floating-label" style={phone ? {transform: "translate(0, 6px) scale(0.75)"} : null} for="phone">
      Phone Number
    </Label>
    <Input
      id="phone"
      name="phone"
      valid={phoneValid ? true : null}
    invalid={!phoneValid && phone ? true : formPressed && !phoneValid ? true : null}
    onChange={(e) => setPhone(e.target.value)}
      type="tel"
    />
  </FormGroup>
  <FormGroup className="form-group">
    <Label className="floating-label" style={instagramHandle ? {transform: "translate(0, 6px) scale(0.75)"} : null} for="instagramHandle">
      Instagram Handle
    </Label>
    <Input
      id="instagram"
      name="instgram"
      type="text"
       valid={instagramHandleValid ? true : null}
           invalid={!instagramHandleValid && instagramHandleValid ? true : formPressed && !instagramHandleValid ? true : null}
          onChange={(e) => setinstagramHandle(e.target.value)}
    />
  </FormGroup>
  <FormGroup className="form-group">
    <Label className="floating-label" style={whyAmbassador ? {transform: "translate(0, 6px) scale(0.75)"} : null} for="whyAmbassador">
      Why do you want to be a Vizopay Ambassador?
    </Label>
    <Input
      id="whyAmbassador"
      name="whyAmbassador"
      type="text"
       valid={whyAmbassadorValid ? true : null}
           invalid={!whyAmbassadorValid && whyAmbassadorValid ? true : formPressed && !whyAmbassadorValid ? true : null}
          onChange={(e) => setwhyAmbassador(e.target.value)}
    />
  </FormGroup>
   
  <FormGroup>
                    <Button onClick={(e) => handleSubmit(e)} style={{color: '#fff', minWidth: '100%', fontWeight: '600', height: '50px'}} color="primary">Submit</Button>
    
  </FormGroup>

    <FormGroup>
        {

            errorMessage ?

            <small style={{color: "red"}} className="success-text-small">Oops... something went wrong.</small>

            :

            successMessage ?

            <small className="success-text-small">Thanks {name}! We can't wait to start working with you.</small>

            :

            <small style={{opacity: 0}}>We can't wait to start working with you.</small>
        }
    </FormGroup>
                    </Form>
                    </Row>


                </Col>
                <Col xl={6}>
                    <div className="form-image-container">
                    <img src={FormImage} width="300px"/>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AmbassadorForm