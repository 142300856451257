import React from 'react'
import OurMissionHeroTop from './OurMissionHeroTop';
import OurMissionHeroTitle from './OurMissionTitle';
import OurMissionHeroBody from './OurMissionBody';
import OurMissionHeroFooter from './OurMissionFooter';


function OurMissionLayout() {
    return (
        <div className="blog-background">
            <OurMissionHeroTop />
            <OurMissionHeroTitle />
            <OurMissionHeroBody />
            <OurMissionHeroFooter />
            
        </div>
    )
}

export default OurMissionLayout