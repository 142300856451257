import React from 'react'

function OurMissionHeroBody () {

    return(
        <>
        <div className='blog-body-container'>
            <div>
                <h2>The Problem</h2>
                <p style={{padding: '1% 0'}}>We recognise there are three types of people:</p>
                <p style={{fontWeight: '500'}}>Eco-Active</p>
                <p>These are people that are willing to sacrifice or spend more to be sustainable, they put sustainability at the forefront of their purchasing decisions.</p>


                <p style={{fontWeight: '600', padding: '5px 0 0 0'}}>The Vizopay Team.</p>
            </div>
        </div>
        </>
    );
}

export default OurMissionHeroBody