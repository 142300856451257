import React from 'react'
import { Row } from 'reactstrap';

function OurMissionHeroFooter () {

    return(
        <>
        <div className='blog-footer-container'>
            <div>
            <Row>
                <h2>
                Tags: 
                <button className='blog-footer-button'>Sustainability</button> 
                <button className='blog-footer-button' style={{backgroundColor: '#135361'}}>Vision</button> 
                </h2> 
            </Row>
            </div>
        </div>
        </>
    );
}

export default OurMissionHeroFooter