import React from 'react'

function PrivacyPolicy() {

    const terms = [
        {
            title: 'Website Visitors',
            text1: 'Like most website operators, VIZOPAY LTD collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. VIZOPAY LTD’s purpose in collecting non-personally identifying information is to better understand how VIZOPAY LTD’s visitors use its website. From time to time, VIZOPAY LTD may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.',
            text2: 'VIZOPAY LTD also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://www.VIZOPAY.co.uk blog posts. VIZOPAY LTD only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.',
            text3: ''
        },
        {
            title: 'Gathering of Personally-Identifying Information',
            text1: 'Certain visitors to VIZOPAY LTD’s websites choose to interact with VIZOPAY LTD in ways that require VIZOPAY LTD to gather personally-identifying information. The amount and type of information that VIZOPAY LTD gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at https://www.VIZOPAY.co.uk to provide a username and email address.',
            text2: '',
            text3: ''
        },
        {
            title: 'Security',
            text1: 'The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.',
            text2: '',
            text3: ''
        },
        {
            title: 'Advertisements',
            text1: 'Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by VIZOPAY LTD and does not cover the use of cookies by any advertisers.',
            text2: '',
            text3: ''
        },
        {
            title: 'Links To External Sites',
            text1: 'Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.',
            text2: 'We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.',
            text3: ''
        },
        {
            title: 'Protection of Certain Personally-Identifying Information',
            text1: 'VIZOPAY LTD discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on VIZOPAY LTD’s behalf or to provide services available at VIZOPAY LTD’s website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using VIZOPAY LTD’s website, you consent to the transfer of such information to them. VIZOPAY LTD will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, VIZOPAY LTD discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when VIZOPAY LTD believes in good faith that disclosure is reasonably necessary to protect the property or rights of VIZOPAY LTD, third parties or the public at large.',
            text2: 'If you are a registered user of https://www.VIZOPAY.co.uk and have supplied your email address, VIZOPAY LTD may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with VIZOPAY LTD and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. VIZOPAY LTD takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.',
            text3: ''
        },
        {
            title: 'Aggregated Statistics',
            text1: 'VIZOPAY LTD may collect statistics about the behavior of visitors to its website. VIZOPAY LTD may display this information publicly or provide it to others. However, VIZOPAY LTD does not disclose your personally-identifying information.',
            text2: '',
            text3: ''
        },
        {
            title: 'Affiliate Disclosure',
            text1: 'This site uses affiliate links and does earn a commission from certain links. This does not affect your purchases or the price you may pay.',
            text2: '',
            text3: ''
        },
        {
            title: 'Cookies',
            text1: 'To enrich and perfect your online experience, VIZOPAY LTD uses “Cookies”, similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.',
            text2: 'A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. VIZOPAY LTD uses cookies to help VIZOPAY LTD identify and track visitors, their usage of https://www.VIZOPAY.co.uk, and their website access preferences. VIZOPAY LTD visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using VIZOPAY LTD’s websites, with the drawback that certain features of VIZOPAY LTD’s websites may not function properly without the aid of cookies.',
            text3: 'By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to VIZOPAY LTD’s use of cookies.'
        },
        {
            title: 'Business Transfers',
            text1: 'If VIZOPAY LTD, or substantially all of its assets, were acquired, or in the unlikely event that VIZOPAY LTD goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of VIZOPAY LTD may continue to use your personal information as set forth in this policy.',
            text2: '',
            text3: ''
        },
        {
            title: 'Privacy Policy Changes',
            text1: 'Although most changes are likely to be minor, VIZOPAY LTD may change its Privacy Policy from time to time, and in VIZOPAY LTD’s sole discretion. VIZOPAY LTD encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.',
            text2: '',
            text3: ''
        },
    ]

    return (
        <main id="content" role="main" style={{backgroundColor: '#65ccb8'}}>
        {/* Privacy Section */}
        <div style={{padding: '9% 5% 5% 5%'}} className="container" >
          <div>
            <div className="card">
              {/* Hero */}
              <div style={{backgroundColor: '#3d978b'}} className="card-header position-relative d-block space-1 space-md-2 p-4 px-md-7 px-md-9">
                <h1 className="text-white">Privacy &amp; Policy</h1>
                <p className="text-white-70">Last modified: March 27, 2021 </p>
                {/* SVG Bottom Shape */}
                <figure className="position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1" width="100%" height={32}>
                    <path fill="#fff"  />
                  </svg>
                </figure>
                {/* End SVG Bottom Shape Section */}
              </div>
              {/* End Hero */}
              {/* Content */}
              <div className="card-body p-4 p-md-9">
                <div className="mb-7">
                  {/* Title */}
                  <div className="mb-3">
                    <h2 className="h3">Introduction</h2>
                  </div>
                  {/* End Title */}
                  {/* Text */}
                  <p>Thanks for using our products and services ("Services"). The Services are provided by Vizopay Ltd. ("Vizopay"), located at 71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ.
                  </p><p>By using our Services, you are agreeing to these terms. Please read them carefully.</p>
                  <p>Our Services are very diverse, so sometimes additional terms or product requirements (including age requirements) may apply. Additional terms will be available with the relevant Services, and those additional terms become part of your agreement with us if you use those Services.</p>
                  {/* End Text */}
                </div>
                {
                    terms.map(data => {
                        return(
                            <div className="mb-7">
                  {/* Title */}
                  <div className="mb-3">
                    <h3>{data.title}</h3>
                  </div>
                  {/* End Title */}
                  {/* Text */}
                  <p>{data.text1}</p>
                  <p>{data.text2}</p>
                  <p>{data.text3}</p>
                  {/* End Text */}
                </div>
                        )
                    })
                }
                
              </div>
              {/* End Content */}
            </div>
          </div>
        </div>
        {/* End Privacy Section */}
      </main>
    )
}

export default PrivacyPolicy