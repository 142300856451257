export const Data = [
  {
     question: 'Who are Vizopay?',
     answer: 'We are the company on a mission to make sustainability easy for everyone! We recognised that there are a large number of people that care about climate change, but to make an impact are forced to pay more or switch away products they truly love. At Vizopay we don’t believe people should choose between what they love and the planet, and that we can create a balance!'
   },
   
  {
     question: 'How does the app work?',
     answer: 'We make it simple, download the Vizopay app from the app store, select your payment method, go to your favourite stores and ask to checkout with Vizopay. After every payment you can track your impact, understand how you are making a difference and show off your digital forest.'
   },
   {
     question: 'What rewards can I get?',
     answer: 'You can recieve digital stamps, percentage discounts and points back. Our partner businesses have the choice to apply as many of these rewards as they like, rewarding you and the planet at the same time!'
   },
   {
     question: 'How do I use points?',
     answer: 'As long as the business offers points back you will keep racking them up. To spend them, click on apply points at at checkout the next time you shop there.'
   },
   {
     question: 'When will you have my favourite brand on the platform?',
     answer: 'We are growing quickly and want to partner with any and all businesses. If you would like to see stores on the platform then let us know in chat, at info@vizopay.com or tell them to reach out to us directly!'
   },
   {
     question: 'When will you be available in my home town? 🏠',
     answer: 'We are making very quick expansion across the UK and hope to be there soon. If you want to see us in your area sooner then let us know in chat or at info@vizopay.com!'
   },
   {
     question: 'How do I suggest new features?',
     answer: 'We are always looking to improve and grow with our users, so if you feel like there is something missing, we want to know! Contact us at feedback@vizopay.com and we will look into it.'
   },
 ];
 