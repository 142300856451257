import React from 'react'
import {Container, Row, Col, Card, CardTitle, CardImg, CardBody, CardText, CardSubtitle} from 'reactstrap';

import NoChange from '../../assets/no-change.svg';
import NoCompromise from '../../assets/no-compromise.svg';
import NoCosts from '../../assets/no-costs.svg';

import {motion} from 'framer-motion'


function AmbassadorWhy() {

    let array = [
        {
            title: "Perks",
            subtitle: "Spread the word and get rewarded!",
            image: NoCosts,
            paragraph: "Get your hands on the lastest features and products we release. Contribute to the future of Vizopay, have your say on where we go next.",
        },
         {
            title: "Responsibilities",
            subtitle: "Be proactive, Tag and Post.",
            image: NoChange,
            paragraph: "Share the movement and get others to be sustainable. Attend and run events on behalf of Vizopay!",
        },
         {
            title: "Requirements",
            subtitle: "Join the team!",
            image: NoCompromise,
            paragraph: "Everyone can apply! We just need you to be active and have a keen interest in making sustainability effortless!",
        },
    ]

    return (
        <Container className="hook-section">
           <div className="hook-container">
            <Row style={{marginRight: "0px"}}>
                <div className="py-5" style={{marginRight: "0px", padding: '0 0 0 5%'}}>
                    <h1>We're looking for talented brand ambassadors!</h1>
                    <p>Come on-board to help make this world a little greener and get your hands on some exclusive ambassador perks!</p>
                </div>
            </Row>
            <Row style={{marginRight: "0px"}}>
                {
                    array.map((item, i) => {
                        return(
                            <Col lg={4} style={{paddingLeft: "24px"}}>
                                <motion.div
                                    key={i}
                                    initial={{opacity: 0, y: -100}}
                                    whileInView={{opacity: 1, y: 0}}
                                    

                                 className="why-card-1">
                                    <div className="top-row">
                                      <div className="image-container">
                                            <img src={item.image}/>
                                      </div>
                                    </div>
                                    <div  className="bottom-row">
                                        <h3>{item.title}</h3>
                                        <h6>{item.subtitle}</h6>
                                        <p>{item.paragraph}</p>
                                    </div>
                                </motion.div>
                            </Col>
                        )
                    })
                }
            </Row>
   
           </div>
        </Container>
    )
}

export default AmbassadorWhy
