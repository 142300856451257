import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { Col, Spinner } from 'reactstrap';
import yesgif from './Images/yes-gif.webp'

import Instagram from './Images/instagram.png'
import Twitter from './Images/twitter.png'
import Facebook from './Images/facebook.png'
import Linkedin from './Images/linkedin.png'

import ApplePay from './Images/apple-google.png'

function Success({match, setStep}) {

    const socials = [
        {
            image: Instagram,
            tag: "@vizopay",
            link: "https://instagram.com/vizopay"
        },
        {
            image: Twitter,
            tag: "@vizopayofficial",
            link: "https://twitter.com/vizopayofficial"
        },
        {
            image: Facebook,
            tag: "@vizopay",
            link: "https://facebook.com/vizopay"
        },
        {
            image: Linkedin,
            tag: "@vizopay",
            link: "https://linkedin.com/company/vizopay"
        }
    ]

        const [town,setTown] = useState(match.params.town)
     const [area,setArea] = useState(match.params.id)
    const [spinner, setSpinner] = useState(false);

    return (
                    <form id="contactForm" className="community-form-container js-validate card p-4 p-md-5">
                <div style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
 <div className="text-center">
                    <h2>Get the app now! 😍</h2>
                    {/* <p>Thanks for registering!! We'll let you know when we're near you. </p> */}
                  </div>
                </div>
                <div style={{
                    flexGrow: 9,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>

                            
                    <a href="https://vizopay.app.link" target="__blank" className="download-app-button">
                        <img src={ApplePay} width={300}/>
                    </a>
  
  
                    

                </div>
                <div style={{
                    flexGrow: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: '100%'
                }}>
                    {
                        socials.map((social) => {
                            return(
                                   <Col>
                                        <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }}>
                                <a href={social.link} target="__blank">
                                        <img height={40} width={40} src={social.image}/>
                                </a>
                                    {/* <span className="social-media-icon">{social.tag}</span> */}
                                    </div>
                                   </Col>
                            )
                        })
                    }
                </div>
                
                  
            </form>
    )
}

export default Success
