import React from 'react';
import { Container, Col, Row} from 'reactstrap'


function FAQ () {
    return (
    <div className='FAQ-container-subheading'>
        <div className='FAQ'>
            <h3>Questions about Vizopay:</h3>
        </div> 

    </div>
    )
  }

  export default FAQ