import React, {useEffect, useState} from 'react'
import Headroom from "headroom.js";
import {Navbar, NavbarBrand, DropdownMenu, NavbarText, NavbarToggler, Collapse, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownItem, Button} from 'reactstrap';
import options from "./HeadroomOptions";

import BrandImage from '../../assets/green.png'

import MobileMenu from './MobileMenu';

import { NavLink, useLocation } from "react-router-dom";

  import { motion, MotionConfig, useViewportScroll } from "framer-motion";


// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}


function NavigationBar() {


  const { scrollY } = useViewportScroll();

  const [hidden, setHidden] = useState(false)
  const [shadow, setShadow] = useState(false)

  const [transparentNav, setTransparentNav] = useState(false)

  const [backgroundWhite, setBackgroundWhite] = useState(false)




  let scrollShadow = {boxShadow: "0 16px 36px 0 rgb(0 0 0 / 6%)"}

    function update() {
      if(scrollY?.current === 0) {
        setShadow(false)
        setBackgroundWhite(false)
      } else if (scrollY?.current < scrollY?.prev) {
      setShadow(true)
      setHidden(false);
      setBackgroundWhite(true)
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
  }

  useEffect(() => {

    scrollY.onChange((e) => {
      update()
    })
  }, [scrollY])

    const variants = {
    /** this is the "visible" key and it's correlating styles **/
    visible: { opacity: 1, y: 0 },
    /** this is the "hidden" key and it's correlating styles **/
    hidden: { opacity: 0, y: -25 }

  };

   let location = useLocation();

    const [navClasseState, setNavClassState] = useState({
    collapseClasses: "",
    collapseOpen: false
  })


  let activeStyle = {
    textDecoration: "underline",
    textDecorationColor: "#65ccb8",
    textDecorationThickness: '2px',
    textUnderlineOffset: '7px'
  };


  const links = [
      {
          route: "/",
          name: "Customers",
          active: true
      },
      {
          route: "/business",
          name: "Businesses",
          active: false
      },
      {
          route: "/projects",
          name: "Projects",
          active: false
      },
      {
          route: "/ambassadors",
          name: "Ambassadors",
          active: false
      }
  ]

    // useEffect(() => {

    //   console.log("ROUTE LOCATION: ", location)

    //     let header = document.querySelector("#header")
           

    //        try {

    //             let headroom = new Headroom(header);
    // // initialise
    // headroom.init();

               
    //        } catch (error) {
    //            console.log(error)
    //        }

    // }, [])


      useEffect(() => {
        if(location.pathname === "/business" || location.pathname === "/projects") {
          setTransparentNav(true)
        } else {
          setTransparentNav(false)
        }
  }, [location.pathname])

    return (
        <header>

<motion.nav
 variants={variants}
      /** it's right here that we match our boolean state with these variant keys **/
      animate={hidden ? "hidden" : "visible"}
      /** I'm also going to add a custom easing curve and duration for the animation **/
      transition={{ type: "spring", bounce: 0.25, ease: [0.25, 0.5, 0.75, 1], duration: 0.5 }}
      /** basic nav styles **/
      className={`${transparentNav && !backgroundWhite ? "transparent-navbar" : "navbar"} navbar-transparent navbar-light navbar-expand-md`}

      style={shadow ? scrollShadow : null}
>
   
    <NavbarBrand href="/">
      <img src={BrandImage} width="130px"/>
    </NavbarBrand>
  
      <Nav
        // className="justify-content-end"
        style={{width: '100%'}}
        className="mr-auto"
        navbar
      >
        
        {
            links.map((link, i) => {
                return(
                    <NavItem>
          <NavLink onClick={() => {
              const newLinks = [...links]
              newLinks[i].active = true

          }} 
          style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }
          className={`nav-link`} to={link.route}>
            {link.name}
          </NavLink>
        </NavItem>
                )
            })
        }
      </Nav>
      <Nav
        // className="justify-content-end"
    
        className="ml-auto"
        navbar
      >

        {

          location.pathname==="/" ? 

           <>
          <Button onClick={(topFunction)} style={{color: '#fff', fontWeight: '600', minWidth: '150px', height: '50px', fontSize: '1rem'}} color="primary">Get Vizopay</Button>
          </>

          :

          location.pathname==="/business" ?

          <>
       
          <a 
          style={{display: "inline", overflow: "hidden", whiteSpace: 'nowrap'}}
          className={`nav-link`} href="https://intercom.help/vizopay/en/" target="__blank">
            Help Centre
          </a>
             <a 
        
          className={`nav-link`} href="https://business.vizopay.com" target="__blank">
            Login
          </a>
          <a className="btn btn-primary center-flex " href="https://business.vizopay.com" target="__blank"  style={{color: '#fff', fontWeight: '600', width: '100px',  margin: "auto 15px", height: '50px', fontSize: '1rem'}} color="primary">Sign up</a>
          </>

          :

          null
        }

      </Nav>
     
   
</motion.nav>
 
            <MobileMenu links={links}/>

        </header>
    )
}

export default NavigationBar
