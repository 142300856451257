import logo from './logo.svg';
import './App.css';
import { render } from "react-dom";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './views/Home/Home'
import BusinessesLayout from './views/Businesses/BusinessesLayout';
import ProjectsLayout from './views/Projects/ProjectsLayout';
import AmbassadorLayout from './views/Ambassador/AmbassadorLayout';
import PrivacyPolicy from './views/Privacy/PrivacyPolicy';
import Terms from './views/Terms/Terms';
import ErrorPage from './views/Error/404';
import SignupCommunityLayout from './views/Community/SignupLayout';
import AppSupport from './views/AppSupport/AppSupport';
import OurMission from './views/OurMission/OurMissionLayout';
import BlogLayout from './views/Blog/BlogLayout'; 


import NavigationBar from './components/Navbars/NavigationBar';
import Footer from './components/Navbars/Footer';


function App() {
  return (
    <Router>
      <NavigationBar />
       <Routes>
       
        <Route path={`/community/*`}  element={<SignupCommunityLayout />}/> 
        <Route path="/"  element={<Home/>}/> 
        <Route path="/business" element={<BusinessesLayout />}/>
        <Route path="/projects" element={<ProjectsLayout />}/>
        <Route path="/ambassadors" element={<AmbassadorLayout />}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route path="/terms" element={<Terms />}/>
        <Route path="/app-support" element={<AppSupport />}/>
        <Route path="/our-mission" element={<OurMission />}/>
        <Route path="/blog" element={<BlogLayout />}/>
        <Route path="*" element={<ErrorPage />}/> 
        
        </Routes>

        <Footer />
    </Router>
  );
}

export default App;
