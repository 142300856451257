import React, {useState} from 'react'
import {Row, Col} from 'reactstrap'


import AppleStore from '../../assets/phones-web/Group-48.png'
import Pay from '../../assets/phones-web/Group-50.png'
import Forest from '../../assets/phones-web/Forest.png'
import Carbon from '../../assets/phones-web/Group-49.png'
import Rewards from '../../assets/phones-web/Reward.png'
import Achievements from '../../assets/phones-web/Achievements.png'
import Home from '../../assets/phones-web/Home.png'



function PhoneSteps() {

    const [activeIndex, setActiveIndex] = useState(0);

    const activeButton = {
        backgroundColor: '#222831',
        padding: "10px 20px",
        borderRadius: 50,
        width: '100%',
        color: '#fff',
        cursor: 'pointer'
    }

    const inactiveButton = {
        
        backgroundColor: '#fff',
        padding: "10px 20px",
        borderRadius: 50,
        width: '100%',
        color: '#222831',
        cursor: 'pointer'
    
    }

    const [featureArray, setFeatureArray] = useState([
        {
            index: 0,
            image: AppleStore,
            active: true,
            text: "1. Download the Vizopay app 📱",
        },
        {
            index: 1,
            image: Pay,
            active: false,
            text: "2. Link your payment method ⚡",
        },
        {
            index: 2,
            image: Rewards,
            active: false,
            text: "3. Click 'pay now' at our partner stores 😎",
        },
        {
            index: 3,
            image: Carbon,
            active: false,
            text: "4. Earn rewards 🚀",
        },
        {
            index: 4,
            image: Achievements,
            active: false,
            text: "5. Understand your impact, unlock achievements 🤑",
        },
        {
            index: 5,
            image: Home,
            active: false,
            text: "6. Be carbon neutral - for free 🌱",
        },
        
    ])

    return (
        

         <div style={{display: 'flex', justifyContent: 'center', alignItems: "center", paddingBottom: '50px'}}>
             <div className="phone-step-card card" style={{width: "90%"}}>
        <div className="card-body">

        <Row>
            <div className="how-heading">
                <h1>How does it works?</h1>
            </div>
        </Row>

            <Row>
                <Col>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <img src={featureArray[activeIndex].image} width={250}/>
                    </div>
                </Col>
                <Col>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%', height: '100%'}}>

                    <ul style={{listStyle: "none", paddingLeft: 0}}>
                        {
                            featureArray.map(feature => {
                                return(
                                    <li style={{listStyle: "none", margin: '20px auto'}} onMouseOver={() => setActiveIndex(feature.index)}>
                                        <div style={feature.index === activeIndex ? activeButton : inactiveButton}>
                                            <i style={{fontSize: '1.1rem', marginRight: 10, color: '#65ccb8'}} className="fas fa-check-circle"></i>
                                            <span style={{fontSize: '1.1rem', fontFamily: "", fontWeight: '600' }}>{feature.text}</span>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    </div>
                    
                </Col>
            </Row>
     
          
        </div>
      </div>
         </div>
    )
}

export default PhoneSteps