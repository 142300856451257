import React, {useState} from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { motion, AnimatePresence } from 'framer-motion'

import Forest from '../../assets/phones-web/Forest.png';
import ApplePay from '../../assets/phones-web/apple-pay.png';
import Discover from '../../assets/phones-web/Discover.png';

import SignupBusiness from './app-steps/signup-business.svg';
import TrainBusiness from './app-steps/train-business.svg';
import AppBusiness from './app-steps/app-business.svg';
import GrowthBusiness from './app-steps/growth-business.svg';

import SignupCustomer from './app-steps/signup-customer.svg';
import DiscoverCustomer from './app-steps/discover-customer.svg';
import LinkcardCustomer from './app-steps/linkcard-customer.svg';
import TrackCustomer from './app-steps/track-customer.svg';

function AppShowcase() {

    let activeButtonColor = {
        backgroundColor: "#135361",
        color: "#fff",
        borderColor: "#135361"

    }
    let inactiveButton = {
        backgroundColor: "#fff",
        color: "#222831",
        borderColor: "#fff"

    }

     let businessSteps = [
         {  
            heading: "Sign up in minutes",
            paragraph: "Get started in lightning speed with no hardware",
            image: SignupBusiness
         },
         {  
            heading: "Book in a training session",
            paragraph: "We quickly show you the platform so you can get the most out of it",
            image: TrainBusiness
         },
         {  
            heading: "Get listed on our app",
            paragraph: "Let's get your store visible to our growing user base",
            image: AppBusiness
         },
          {  
            heading: "Reach, Retain, Increase, Showcase",
            paragraph: "Let's grow your business together",
            image: GrowthBusiness
         },
        
    ]

    let customerFeatures = [
         {  
            heading: "Sign up in seconds",
            paragraph: "We make it simple and easy to be sustainable",
            image: SignupCustomer
         },
                  {  
            heading: "Link their card or payment method",
            paragraph: "Use Apple Pay, Google Pay and Card to pay",
            image: DiscoverCustomer
         },
                  {  
            heading: "Find your store & pay",
            paragraph: "Get seen by a new consumer base",
            image: LinkcardCustomer
         },
                  {  
            heading: "Track their impact",
            paragraph: "Gamified to keep them coming back to you!",
            image: TrackCustomer
         },
    ]

    let [activeIndex, setActiveIndex ] = useState(businessSteps[0])
    const [toggleIndex, setToggleIndex] = useState("business")

    return (
        <Container fluid className="app-showcase-container">
            <div className="app-showcase-holder">
                

                <div className='business-button-small'>
                <Row style={{flexGrow: 1, marginBottom: 30}}>
                    <Col className="app-left-col-small" sm={6} >
                        <Button 
                       
                        onClick={() => {
                            setToggleIndex("business")
                            setActiveIndex(businessSteps[0])
                            }} style={toggleIndex==="business" ? activeButtonColor : inactiveButton}>Your Business</Button>
                    </Col>
                    <Col className="app-right-col" sm={6}>
                        <Button 
                        onClick={() => {
                            setToggleIndex("customer")
                            setActiveIndex(customerFeatures[0])
                            
                            }} style={toggleIndex==="customer" ? activeButtonColor : inactiveButton}>Your Customer</Button>
                    </Col>
                </Row>
                </div>
                
                
                <div className='business-button-div'>
                <Row style={{flexGrow: 1, marginBottom: 30}}>
                    <Col className="app-left-col" sm={6} >
                        <Button 
                       
                        onClick={() => {
                            setToggleIndex("business")
                            setActiveIndex(businessSteps[0])
                            }} style={toggleIndex==="business" ? activeButtonColor : inactiveButton}>Your Business</Button>
                    </Col>
                    <Col sm={6}>
                        <Button 
                        onClick={() => {
                            setToggleIndex("customer")
                            setActiveIndex(customerFeatures[0])
                            
                            }} style={toggleIndex==="customer" ? activeButtonColor : inactiveButton}>Your Customer</Button>
                    </Col>
                </Row>
                </div>

                
                <Row style={{flexGrow: 11, marginTop: 30}}>
                    <Col  md={5}>
                    <div style={{display: "flex", height: '100%', width: "100%", justifyContent: 'center', alignItems: 'center'}}>
                        <AnimatePresence exitBeforeEnter>
                                    <motion.div
                                    
                                    className="action-container"
                                    key={activeIndex.heading}
                                    animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 100 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.3 }}
                                    >
                                        <img src={activeIndex.image} width="80%"/>
                                    </motion.div>
                                </AnimatePresence>
                    </div>
                                

                    </Col>
                    <Col md={7}>
                        <div className="app-steps-container">
                                <AnimatePresence exitBeforeEnter>
                            {

                            toggleIndex === "business"

                            ?

                            businessSteps.map((step, i) => {
                                return(
                                    <motion.div
                                    key={i} 
                                    initial={{scale: 0.3, opacity: 0}}
                                    whileInView={{scale: 1, opacity: 1}}
                                    whileHover={{scale: 1.05,}}
                                    
                                    transition={{
                                        duration: `0.${i+2}`,
                                        type:'spring',
                                        stiffness: 100
                                    }}
                                    onMouseOver={() => {
                                        setActiveIndex(businessSteps[i])
                                        
                                        }}
                                    className="step-container">
                                        <h1>{step.heading}</h1>
                                        <h4>{step.paragraph}</h4>
                                    </motion.div>
                                )
                            })

                            :

                             customerFeatures.map((step, i) => {
                                return(
                                    <motion.div 
                                    key={i}
                                     initial={{scale: 0.3, opacity: 0, }}
                                    whileInView={{scale: 1, opacity: 1}}
                                    whileHover={{scale: 1.05,}}
                                    onMouseOver={() => setActiveIndex(customerFeatures[i])}

                                    transition={{
                               
                                        type:'spring',
                                        stiffness: 100
                                    }}

                                    className="step-container">
                                        <h1>{step.heading}</h1>
                                        <h4>{step.paragraph}</h4>
                                    </motion.div>
                                )
                            })

                        }
                        </AnimatePresence>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default AppShowcase
