import React from 'react'
import BusinessHeroTop from './BusinessHeroTop';
import BusinessForm from './BusinessForm';
import Explainer from './Explainer';
import HowItWorks from './HowItWorks';
import Incentives from './Incentives';
import AppDiagram from './AppDiagram';
import AppShowcase from './AppShowcase';



function BusinessesLayout() {
    return (
        <div className="business-section">
            <BusinessHeroTop />
            <HowItWorks />
            {/* <AppDiagram /> */}
            <AppShowcase />
            <Incentives />
            <BusinessForm />
        </div>
    )
}

export default BusinessesLayout
