import React from 'react'
import { Container, Row, Col } from 'reactstrap';

let arrow = <svg className="arrows" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 371.11 494.15"><defs><style dangerouslySetInnerHTML={{__html: ".arrow-1{fill:#65ccb8;}" }} /></defs><path className="arrow-1" d="M405.28,201.19,130.8,13.28C118.13,4.6,105.36,0,94.74,0,74.22,0,61.52,16.47,61.52,44V450.17c0,27.54,12.68,44,33.16,44,10.63,0,23.2-4.6,35.9-13.31l274.61-187.9c17.66-12.11,27.44-28.4,27.44-45.89S423,213.29,405.28,201.19Z" transform="translate(-61.52)" /></svg>


const arrows = Array(10).fill(arrow);


function Explainer() {


    return (
        <Container className="explainer-section">
            <Row>
<div  style={{height: '100%', width: '100%', padding: "20px 0", display: 'flex'}}>
{
    arrows.map((arrow) => {
        return(
            arrow
        )
    })
}
</div>
            </Row>
        </Container>
    )
}

export default Explainer
