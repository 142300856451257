import React from 'react'
import { Container, Col, Row } from 'reactstrap'

import Image from '../../assets/Double.png'

import AS from '../../assets/app/black.svg';
import AS1 from '../../assets/app/apple.png';
import GS from '../../assets/app/google-play-badge.png';
import ASGS from '../../assets/app/apple_google_icon.png'
import ASGS1 from '../../assets/app/google_apple_icons.png'


// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }


function CTA() {
    return (
        <Container fluid className="cta-footer">
            <Row>
                <Col md={6}>
                    <Row>
                        <h1 style={{paddingTop: '5%'}}>
                        Make your next purchase carbon neutral.
                        <br />
                        <br />
<span style={{color: '#65ccb8'}}>Easy as 1-2-🌳</span>
                    </h1>
                    </Row>

                    <Row>
                        <p style={{color: '#ffffff'}}>
                            net zero
                        </p>
                    </Row>

                    <Row>
                        <Col>
                            <div className="cta-image-apple-google" onClick={(topFunction)}>
                                <img src={ASGS1}/>
                            </div>
                         </Col>
                        
                    </Row>
                    
                    {/* <Row>
                        <Col>
                            <div className="cta-image-apple-google" onClick={(topFunction)}>
                                <img src={ASGS}/>
                            </div>
                         </Col>
                        
                    </Row> */}


                    {/* <Row>
                        <Col>
                            <div className="cta-image-apple" onClick={(topFunction)}>
                                <img src={AS1}/>
                            </div>
                         </Col>
                        
                        <Col md={9}>
                            <div className="cta-image-google" onClick={(topFunction)}>
                                <img src={GS}/>
                            </div>
                        </Col>
                    </Row> */}

                </Col>
                <Col md={6}>
                    <div className="cta-image">
                        <img src={Image}/>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default CTA
