import React from 'react'

function OurMissionHeroTitle () {

    return(
        <>
        <div className='blog-title-container'>
            <div>
                <h1>Our Mission</h1>
                <p>We aim to make sustainability easy for everyone! A win-win solution that allows consumers, businesses and the planet to benefit with every purchase.</p>
                <a>May 20, 2022</a>
            </div>
        </div>
        </>
    );
}

export default OurMissionHeroTitle