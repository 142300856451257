import React, {useContext, useEffect}  from 'react'
import {Col, Container, Row } from 'reactstrap'
import PI from '../../views/Ambassador/Hero.jpg';


function AmbassadorPerks() {
    return (
        <Container>
        <Row>
        <Col>
            <Row>
            <div className="perks-second-img">
                <img src={PI}/>
            </div>
            </Row>
        </Col>
        <Col>
            <Row>
                <div className="ambassador-text-perks-h3">
                    <h3>Ambassador Perks</h3>
                </div>
            </Row>
            <Row>
                <h5>Early Access</h5>
                <p className="ambassador-text-perks-p">To the newest product releases</p>
            </Row>
            <Row>
                <h5>Best Place to work</h5>
            </Row>
        </Col>
        </Row>
        </Container>
    )
}

export default AmbassadorPerks
