import React from 'react'
import {Container, Row, Col, Button, Alert, Badge} from 'reactstrap'

import './home.css'
import HeroTop from './HeroTop';
import ExplainerSection from './ExplainerSection';
import PageScroller from '../../components/General/PageScroller';
import HeroTopAlt from './HeroTopAlt';
import WhyVizopay from './WhyVizopay';
import HowVizopay from './HowVizopay';
import CTA from './CTA';
import FAQ from './FAQ';
import FAQAccordian from './FAQAccordian';
import FAQSubheading1 from './FAQ-subheading-1'
import FAQAccordian1 from './FAQAccordian-1';
import FAQSubheading2 from './FAQ-subheading-2'

function Home() {

    const buttons = [
        <Button style={{color: '#fff'}} color="primary">Primary</Button>,
            <Button style={{color: '#fff'}} color="secondary">Secondary</Button>,
            <Button style={{color: '#fff'}} color="info">Info</Button>,
            <Button style={{color: '#fff'}} color="success">Success</Button>,
            <Button style={{color: '#fff'}} color="warning">Warning</Button>,
            <Button style={{color: '#fff'}} color="danger">Danger</Button>
    ]

       const badges = [
        <Badge style={{color: '#fff'}} color="primary">Primary</Badge>,
            <Badge style={{color: '#fff'}} color="secondary">Secondary</Badge>,
            <Badge style={{color: '#fff'}} color="info">Info</Badge>,
            <Badge style={{color: '#fff'}} color="success">Success</Badge>,
            <Badge style={{color: '#fff'}} color="warning">Warning</Badge>,
            <Badge style={{color: '#fff'}} color="danger">Danger</Badge>
    ]

    const alerts = [
            <Alert color="primary" toggle={function noRefCheck(){}}>I am primary alert and I can be dismissed!</Alert>,
            <Alert color="secondary" toggle={function noRefCheck(){}}>I am secondary alert and I can be dismissed!</Alert>,
            <Alert color="info" toggle={function noRefCheck(){}}>I am info alert and I can be dismissed!</Alert>,
            <Alert color="success" toggle={function noRefCheck(){}}>I am success alert and I can be dismissed!</Alert>,
            <Alert color="warning" toggle={function noRefCheck(){}}>I am warning alert and I can be dismissed!</Alert>,
            <Alert color="danger" toggle={function noRefCheck(){}}>I am danger alert and I can be dismissed!</Alert>,


    ]

    return (
        <Container className="customer-section" fluid>

            {/* <HeroTop /> */}
            <HeroTopAlt />
            <WhyVizopay />
            <HowVizopay />
            <CTA />
            <FAQ />
            <FAQSubheading1 />
            <FAQAccordian />
            <FAQSubheading2 />
            <FAQAccordian1 />

        </Container>
    )
}

export default Home
