import React from 'react'
import { Col, Container, Row } from 'reactstrap'

function CarbonRemoval() {
    return (
        <Container>
            <Row>
                <div className="carbon-removal-container">
                    <h2>Carbon removal is critical to counteract climate change</h2>
                </div>
            </Row>
            <Row>
                <Col>
                    To prevent the most catastrophic effects of climate change, we should aim to limit global average temperature increase to 1.5°C above pre-industrial levels, which corresponds to reducing global annual CO₂ emissions from about 40 gigatons per year as of 2018, to net zero by 2050.
                </Col>
                <Col>
                    To accomplish this, the world will likely need to both radically reduce the new emissions we put into the air, and remove carbon already in the atmosphere.
                </Col>
            </Row>
        </Container>
    )
}

export default CarbonRemoval
