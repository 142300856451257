import React, {useState} from 'react'

import axios from 'axios';
import url from '../../api/url';
import "../../App.css"
import {Spinner} from 'reactstrap'


import { Link } from 'react-router-dom';

function SignupForm({match, setStep}) {

    const [firstName, setFirstName] = useState("");
    const [area,setArea] = useState("")
    const [town,setTown] = useState("n")
    const [email, setEmail] = useState("");
    const [storeSuggestions, setstoreSuggestions] = useState("");
    const [check, setCheck] = useState(false);

    const [platform, setPlatform] = useState("iOS")

    const [spinner, setSpinner] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState(false);

    var url_string = window.location.href
var client_url = new URL(url_string);
var referral_param = client_url.searchParams.get("referral");
var general_param = client_url.searchParams.get("general");


    const [referral, setReferral] = useState(referral_param ? referral_param : "")
    
    const saveSignupForm = async(e) => {

      e.preventDefault();

      setSpinner(true);

      let body = {
            first_name: firstName,
            email: email,
            stores_suggestion: storeSuggestions,
            referral: referral,
            city:area,
            town:town,
            platform: platform
      }

      console.log("BODY: ", body)

      if(!firstName) {
        document.getElementById('firstName').style.border="1px solid #cf0000"
      }



       if(!email) {
        document.getElementById('emailForm').style.border="1px solid #cf0000"
      }

      

      if(!check || !body.first_name || !body.email ) {
        setErrorMessage("Please complete required fields.");
        setSuccessMessage(false)
        setSpinner(false);

        return;
      } else {

        await axios.post(`${url}/signup/community/${area}`, body)
                    .then(res => {
                      console.log(res.data, "Sent")
                      
                  
                        setStep(1)
                      

                      setErrorMessage("")
                      setSpinner(false)

                      document.getElementById('firstName').style.borderColor="0.0625rem solid #e7eaf3"
                      document.getElementById('emailForm').style.border="0.0625rem solid #e7eaf3"


                    })
                    .catch(err => {
                      console.log(err.response);
                      setSuccessMessage(false)
                      setErrorMessage("Oops... something has gone wrong. Please try again.")
                      setSpinner(false)
                    })
      }

       
    }


    return (
  

          
                <form id="contactForm" className="community-form-container js-validate card p-4 p-md-5">
                  <div className="text-center mb-4">
              
                    <h2>Hey there 👋</h2>

                    <p>Who wants to make sustainability easy 🙋‍♀️</p>
                  </div>
                  <div className="row mx-n2">
                    <div className="col-md-12 px-2 mb-4">
                      {/* Input */}
                      <label className="input-label">First name*</label>
                      <div className="js-form-message">
                        <div className="input-group">
                          <input id="firstName" onChange={(e) => setFirstName(e.target.value)} type="text" className="form-control" name="firstName" placeholder="First name" aria-label="First name" required data-msg="Please enter your first name." />
                        </div>
                      </div>
                      {/* End Input */}
                    </div>
                    <div className="w-100" />
                    <div className="col-md-12 px-2 mb-4">
                      {/* Input */}
                      <label className="input-label">Email address*</label>
                      <div className="js-form-message">
                        <div className="input-group">
                          <input id="emailForm" onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" name="email" placeholder="Email address" aria-label="Email address" required data-msg="Please enter a valid email address." />
                        </div>
                      </div>
                      {/* End Input */}
                    </div>
                  </div>
                  {
                    general_param || !general_param ?

                    <>
                    <div className="row mx-n2">
                
                    <div className="w-100" />
                    <div className="col-md-12 px-2 mb-4">
                      {/* Input */}
                      <label className="input-label">City/Town*</label>
                      <div className="js-form-message">
                        <div className="input-group">
                          <input id="town" onChange={(e) => setArea(e.target.value)} type="text" className="form-control" name="town" placeholder="City/Town" aria-label="City/Town" required data-msg="Please enter a valid email address." />
                        </div>
                      </div>
                      {/* End Input */}
                    </div>

                    
                  </div>

<div className="row mx-n2">
                  <div className="w-100" />
                    <div className="col-md-12 px-2 mb-4">
                       <div className="mb-3">
        <label className="input-label">Platform*</label>
        <div className="input-group input-group-merge">
         
          <select onChange={(e) => setPlatform(e.target.value)} id="inputGroupMergeGenderSelect" className="form-control">
            <option>iOS</option>
            <option>Android</option>
          </select>
        </div>
      </div>
                    </div>
                    </div>
             
                  </>

                  :

                  null
                  }
                  {/* Input */}
                  {/* <div className="mb-5">
                    <label className="input-label">What stores would you like to see in your area? (optional)</label>
                    <div className="js-form-message input-group">
                      <textarea onChange={(e) => setstoreSuggestions(e.target.value)} className="form-control" rows={1} name="description" placeholder="Coffee Hut, Gail's Bakery... " aria-label="Fannys-kebabs,annabels ... " required data-msg="Please enter a reason." defaultValue={""} />
                    </div>
                  </div> */}
                  {/* End Input */}
                  {/* Checkbox */}
             
                  <div className="js-form-message mb-3">
                    <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
                      <input onChange={() => setCheck(!check)} type="checkbox" className="custom-control-input" id="termsCheckboxExample1" name="termsCheckboxExample1" required data-msg="Please accept our Terms and Conditions." />
                      <label className="custom-control-label" htmlFor="termsCheckboxExample1">
                        <small>
                          I agree to the
                          <Link className="link-underline" to="/terms">Terms and Conditions</Link>
                        </small>
                      </label>
                    </div>
                  </div>
                  {/* End Checkbox */}
                  {/* Checkbox */}
                  {
                    successMessage ? 

                    <div className="js-form-message mb-3">
                        <p className="text-success">All done! Thank you for registering your interest.</p>
                    </div>

                    :

                    null
                  }

                  <div className="js-form-message mb-3">
                        <p className="text-danger">{errorMessage}</p>
                    </div>
                  
                  {/* End Checkbox */}
                  <button onClick={saveSignupForm} type="submit" className="btn btn-success transition-3d-hover">
                    {
                      spinner ?
                      <Spinner color="success"/>
                      :
                      "Submit"
                    }
                  </button>
                </form>
             
    )
}

export default SignupForm
