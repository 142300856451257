import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Statistics from '../../assets/Images/statistics-01.png'
import Location from '../../assets/Images/location-01.png'
import Repeat from '../../assets/Images/repeat-01.png'
import Network from '../../assets/Images/network-01.png'
import Idea from '../../assets/Images/idea-01.png'
import Pay from '../../assets/Images/pay-01.png'
import { motion } from 'framer-motion'

function Incentives() {

    const firstRow = [        
        {
            icon: Statistics,
            heading: "Grow your Business",
            text: "Leverage our growing user base to grow your business."
        },
        {
            icon: Location,
            heading: "Digital Acquisition",
            text: "Acquire customers looking for options on their phones to come into your store."
        },
        {
            icon: Repeat,
            heading: "Retain & Repeat",
            text: "Spenders become loyal to Vizopay partners (you!)."
        },       
    ]

    const secondRow = [
        {
            icon: Network,
            heading: "Network Exposure",
            text: "We incentivise customers to keep spending at our partners."
        },
        {
            icon: Idea,
            heading: "Data Driven Analytics",
            text: "User data understand your customers."
        },
        {
            icon: Pay,
            heading: "Digital Payments",
            text: "Accept digital payments without any additional infrastructure."
        },

    ]


    return (
        <Container className="incentives-section" style={{ zIndex: 2, backgroundColor: '#fff'}}>
            <Row style={{paddingTop: 50, paddingBottom: 20, paddingRight: 10, paddingLeft: 10}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    paddingTop: 20,
                    paddingBottom: 20
                }}>
                    <h1>What do we do for you?</h1>
                </div>
            </Row>
            <Row style={{paddingRight: 50, paddingLeft: 50}}>
                {
                    firstRow.map((feature, i) => {
                        return(
                            <Col md={4} style={{marginTop: 20, marginBottom: 20}}>
                                    <img style={{marginTop: 20, marginBottom: 20}} src={feature.icon} width={60}/>
                                    <h6 style={{marginTop: 20, marginBottom: 10}}>{feature.heading}</h6>
                                    <p style={{marginTop: 10, marginBottom: 20}}> {feature.text}</p>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row style={{paddingRight: 50, paddingLeft: 50}}>
                {
                    secondRow.map((feature, i) => {
                        return(
                            <Col md={4} style={{marginTop: 20, marginBottom: 20}}>
                                   <img style={{marginTop: 20, marginBottom: 20}} src={feature.icon} width={60}/>
                                    <h6 style={{marginTop: 20, marginBottom: 10}}>{feature.heading}</h6>
                                    <p style={{marginTop: 10, marginBottom: 20}}> {feature.text}</p>
                                
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    )
}

export default Incentives
