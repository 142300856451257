import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Container } from 'reactstrap';
import  Image from './404.svg'; 


function errorPage() {
    return (
        <div style={{
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            flexDirection: 'column'
        }}>
            <img src={Image} height={300} width={300} />
            <h2>Oops... we couldn't find that page!</h2>
            <Link to="/">
            <button type="submit" className="btn btn-success transition-3d-hover" style={{color: '#fff', fontWeight: '800'}}>Return home</button>

            </Link>
        </div>
    )
}

export default errorPage