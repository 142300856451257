import React, {useEffect} from 'react'
import {Container, Row, Col, Button} from 'reactstrap';

import VideoBg from '../../assets/video-bg.mp4'
import EcoConscious from "../../assets/Images/undraw_eco_conscious_re_r2bs.svg"

function BusinessHeroTop() {

  // useEffect(() => {
  //   var vid = document.getElementById("myVideoMobile");
  //   vid.autoplay = false;
  // }, [])

    return (
      <>
        <Container fluid className="business-hero-top-container">
            
       {/* <video playsInline autoPlay muted loop id="myVideo">
        <source src={VideoBg} type="video/mp4" />
      </video> */}

      {/* <video playsInline muted loop id="myVideoMobile">
        <source src={VideoBg} type="video/mp4" />
      </video> */}
            {/* <div className="business-hero-overlay">
            
            </div> */}
            <Row style={{zIndex: 2}}>
             
                  <Col lg={9}>
                      <div className="hero-top-cta">
                    <h1>Grow your business with eco-friendly spenders</h1>
                    <small>Join Vizopay’s growing merchant network to attract, gain and retain the next-gen of eco-conscious spenders</small>
                    <Button target="_blank" href="https://business.vizopay.com" class="btn btn-info a-button mobile-button" color='primary' style={{color: '#fff', display: 'flex', justifyContent: 'center', alignContent: 'center', fontWeight: '800', height: '50px', minWidth: '150px', fontSize: '20px', paddingTop: '10px'}} >Try for free</Button>
                
                    </div>
                  </Col>
                  <Col lg={3}>
                    {/* <img width="100%" src={EcoConscious}/> */}
                  </Col>
         {/* style={{color: '#fff', minWidth: '150px', display: 'flex', justifyContent: 'center', alignContent: 'center', fontWeight: '800', height: '50px', fontSize: '18px'}} */}

            </Row>
            
          
        </Container>
        
        </>
    )
}

export default BusinessHeroTop
