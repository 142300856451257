import React, {useState} from 'react'
import { Col, Row, Spinner } from 'reactstrap'

import Discover from './Images/research.png'
import Download from './Images/download.png'
import Pay from './Images/credit-card.png'
import Track from './Images/report.png'


import Apple from './Images/black.svg'
import Android from './Images/google-play-badge.png'


function Introduction({step, setStep, match}) {
    // const [town,setTown] = useState(match.params.town)
    //  const [area,setArea] = useState(match.params.id)
    const [spinner, setSpinner] = useState(false);

    var url_string = window.location.href
var url = new URL(url_string);
var referral_id = url.searchParams.get("referral");

    const steps = [
        {
            header: "Discover",
            icon: Discover,
            text: "We partner with brands to help them become net-zero 🌱"
        },
        {
            header: "Download",
            icon: Download,
            text: "You download our App to find brands in your area and get exclusive offers 🎁"
        },
        {
            header: "Pay",
            icon: Pay,
            text: "Pay in seconds and never pay a penny more. Easy as ⚡"
        },
        {
            header: "Earn",
            icon: Track,
            text: "Get rewarded and track your impact. It's sustainability made simple 😍"
        },
    ]

    return (

            <form id="contactForm" className="community-form-container js-validate card p-4 p-md-5">
                <div style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
 <div className="text-center">
                    <h2>Hey there 👋</h2>
                    <p>We're on a mission to make sustainability effortless for everyone! Here's how it works:</p>
                  </div>
                </div>
                <div style={{
                    flexGrow: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                
                    height: '100%'
                }}>
                   <Row>
                                         {
                        steps.map((step )=> {
                            return(
                                <Col style={{textAlign: 'center'}}>
                                    <img src={step.icon} width={36}/>
                                    <h6 style={{margin: "10px auto"}}>{step.header}</h6>
                                </Col>
                            )
                            
                            })
                                         }
                                        </Row>
                </div>
                     
                <div style={{
                    flexGrow: 7,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                
                    height: '100%'
                }}>

                    

                    
                                  
                                         <div>

                                

                <div
                    style={
                        {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent:'center',
                            alignItems: 'center',
                            width: '100%',
                            paddingTop: 10,
                            
                        }
                    }
                >

                <a href="https://vizopay.app.link" target="__blank">
                    <img src={Apple} height={50}/>
                </a>

                
                <a href="https://vizopay.app.link" target="__blank">
                    <img src={Android} height={68}/>
                </a>
                </div>
                
            </div>
        
                              
                        

                </div>
                <div style={{
                    flexGrow: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: '100%'
                }}>
                
                <a href={`https://vizopay.app.link/${referral_id}`} style={{width: '100%', zIndex: 3}} className="btn btn-success transition-3d-hover" >

                    Download the App
                  </a> 
                </div>
                
                  
            </form>

    )
}

export default Introduction
